import React, { useEffect, useState, useRef } from "react";
import useMeta from "components/useMeta";

import { useAppSelector } from "redux/hooks";

import { Vehicle } from "redux/features/VehiclesTypes";

import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Item from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FaCheck } from "react-icons/fa";



import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";

import useLanguage from "hooks/useLanguage";

import useSwipe from "helpers/useSwipe";
import useDrag from "helpers/useDrag";

import { capitalize, htmlEntitiesDecode, toLocaleDate, intToLocaleString } from "helpers/stringHelper";

import { BounceAnimation } from "hooks/useAnimations";

type Props = any;

const MAX_COLUMNS = 3;


const DynamicColumns = (vehicle: Vehicle) => {
    if (vehicle && vehicle.equipment_details_translated) {

        const itemsPerColumn = Math.ceil(vehicle.equipment_details_translated.length / MAX_COLUMNS);
        const columns = Array.from({ length: MAX_COLUMNS }, (_, columnIndex) => {
            const startIndex = columnIndex * itemsPerColumn;
            const endIndex = startIndex + itemsPerColumn;
            return vehicle.equipment_details_translated.slice(startIndex, endIndex);
        });

        return (
            <Grid container sx={{
                paddingTop: "0px",
            }} spacing={0}>
                {columns.map((column, columnIndex) => (
                    <Grid item xs={12} md={4} key={columnIndex}>
                        <List sx={{ gap: 0, paddingTop: "0px", paddingBottom: "0px" }}>
                            {column.map((detail, detailIndex) => (
                                <ListItem key={detailIndex}>
                                    <ListItemIcon>
                                        <FaCheck />
                                    </ListItemIcon>
                                    <ListItemText primary={capitalize(detail)} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                ))}
            </Grid>
        );
    } else {
        return null;
    }
}

const AanbodDetail = (props: Props) => {
    const vehicles = useAppSelector((state) => state.vehicleStateSlice.vehicles);
    const [vehicle, setVehicle] = useState( null as Vehicle | null);
    const [currentPage, setCurrentPage] = useState(0);
    const [slideDirection, setSlideDirection] = useState<"right" | "left" | undefined>("left");

    // useref
    const root = useRef(document.getElementById("root"));

    const [imageToShow, setImageToShow] = useState("");
    const [lightboxDisplay, setLightBoxDisplay] = useState(false);

    const params = new URLSearchParams(window.location.search);
    const id = params.get('id') ?? '';
    if (id === '') {
        window.location.href = '/aanbod';
    }

    useEffect(() => {
        setVehicle(vehicles.find(vehicle => vehicle.internal_id === parseInt(id)) as Vehicle);
        // console.log(vehicle);
    }, [vehicles]);

    const IconStyles = {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 2,
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "white",
        borderRadius: "50%",
        cursor: "pointer",
        ":hover": {
            backgroundColor: "rgba(0,0,0,0.5)",
        },
        ":disabled": {
            backgroundColor: "rgba(0,0,0,0.4)",
            color: "rgba(255,255,255,0.5)",
        },
    }
    
    const swipeHandlers = useSwipe({ onSwipedLeft: () => handleNextPage(), onSwipedRight: () => handlePrevPage() });
    const dragHandlers = useDrag({ onDragLeft: (e) => handlePrevPage(), onDragRight: (e) => handleNextPage() });

    const combinedHandlers = {
        ...swipeHandlers,
        ...dragHandlers,
    };

    function convertHtmlToPlainText(html: string): string {
        // Verwijder alle HTML-tags, behalve <br> en <p> die voor regelafbrekingen zorgen
        let text = html.replace(/<(?!br\s*\/?|\/p)[^>]+>/g, '');
      
        // Vervang <br> en <p> tags door regelafbrekingen
        text = text.replace(/<br\s*\/?>/g, '\n');
        text = text.replace(/<\/?p>/g, '\n');
      
        // Verwijder eventuele opeenvolgende regelafbrekingen
        text = text.replace(/\n\s*\n/g, '\n');
      
        // Trim whitespace aan het begin en einde van de string
        text = text.trim();
      
        return text;
    }

    const handleNextPage = () => {
        setSlideDirection("left");

        if (typeof vehicle !== "undefined" && vehicle !== null && typeof vehicle.images.image !== undefined && vehicle.images.image !== null) {
            if (currentPage >= vehicle.images.image.length - 1) {
                setCurrentPage(0);
            } else {
                setCurrentPage((prevPage) => prevPage + 1);
            }
        } else {
            setCurrentPage(0);
        }
    };
    
    const handlePrevPage = () => {
        setSlideDirection("right");
        if (currentPage === 0) {
            if (typeof vehicle !== "undefined" && vehicle !== null && typeof vehicle.images.image !== undefined && vehicle.images.image !== null) {
                setCurrentPage(vehicle.images.image.length - 1);
            } else {
                setCurrentPage(0);
            }
        } else {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const showImage = () => {
        setImageToShow(vehicle?.images.image[currentPage] ?? "");
        setLightBoxDisplay(true);

        if (root.current) {
            root.current.style.height = "100dvh";
            root.current.style.overflow = "hidden";
        }
    };

    const hideLightBox = () => {
        setLightBoxDisplay(false);

        if (root.current) {
            root.current.style.height = "fit-content";
            root.current.style.overflow = "unset";
        }
    };

    const showNext = (e: any) => {
        e.stopPropagation();

        const currentIndex = vehicle?.images.image.indexOf(imageToShow) ?? 0;

        if (vehicle?.images.image !== undefined && vehicle?.images.image !== null) {
            if (currentIndex >= vehicle?.images.image.length - 1) {
                // If last image, reset to first
                setImageToShow(vehicle?.images.image[0] ?? "");
            } else {
                const nextImage = vehicle?.images.image[currentIndex + 1];
                setImageToShow(nextImage);
            }
        } else {
            setLightBoxDisplay(false);
        }
    };

    const showPrev = (e: any) => {
        e.stopPropagation();

        const currentIndex = vehicle?.images.image.indexOf(imageToShow) ?? 0;

        if (vehicle?.images.image !== undefined && vehicle?.images.image !== null) {
            if (currentIndex <= 0) {
                // If first image, show last
                setImageToShow(vehicle?.images.image[vehicle?.images.image.length - 1])
            } else {
                const nextImage = vehicle?.images.image[currentIndex - 1];
                setImageToShow(nextImage);
            }
        } else {
            setLightBoxDisplay(false);
        }
    };

    const { t, activeLanguage } = useLanguage();

    const absoluteStyles = {
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 999,
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "white",
        position: "absolute",
        borderRadius: "50%",
        cursor: "pointer",
        ":hover": {
            backgroundColor: "rgba(0,0,0,0.5)",
        },
    };

    const carDetailClasses = '"text-center bg-white min-h-[80px] max-w-[200px] md:max-w-full m-auto rounded-xl flex flex-col justify-center items-center';

    let description = '';

    if (vehicle && vehicle.notes && vehicle.notes.note && vehicle.notes.note[1] && vehicle.notes.note[1].description) {
        description = htmlEntitiesDecode(convertHtmlToPlainText(vehicle.notes.note[1].description));
    }

    return (
        useMeta({
            title: 'Kegels Turnhout - ' + vehicle?.make + ' ' + vehicle?.model,
            description: t("Car Detail"),
            keywords: '',
        }),
        <React.Fragment>
            <Container>
                {
                    lightboxDisplay ? 
                        <div id="lightbox" className="absolute inset-0 bg-[#0000008c] flex z-[1200]" onClick={hideLightBox}>
                            <IconButton sx={
                                {
                                    ...absoluteStyles,
                                    left: "1%",
                                }
                            } aria-label="vorige" onClick={showPrev}>
                                <NavigateBeforeIcon />
                            </IconButton>

                            <img id="lightbox-img" className="lg:min-h-[75%] xl:min-h-[90%] object-cover max-w-[75%] max-h-[90%] m-auto" src={imageToShow}></img>

                            <IconButton sx={
                                {
                                    ...absoluteStyles,
                                    right: "1%",
                                }
                            } aria-label="volgende" onClick={showNext}>
                                <NavigateNextIcon />
                            </IconButton>

                            <IconButton sx={
                                {
                                    ...absoluteStyles,
                                    top: "4%",
                                    right: "1%",
                                }
                            } aria-label="volgende" onClick={hideLightBox}>
                                <ZoomOutIcon />
                            </IconButton>
                        </div>
                    : ""
                }

                <Grid className="my-10" container spacing={2} columns={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={2} columns={12}>
                            <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                                <Item>
                                    {(
                                        typeof vehicle !== "undefined" && vehicle !== null &&
                                        typeof vehicle.images.image !== undefined && vehicle.images.image !== null
                                    ) && vehicle.images.image.length > 0 && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                                height: "auto",
                                                width: "100%",
                                            }}
                                        >
                                            <Box className="rounded-lg overflow-hidden" sx={{ width: `100%`,height: "100%" }}>
                                                {(vehicle.images.image.length > 0) && vehicle.images.image.map((thumbnail: string, index: number) => (
                                                    <Box
                                                        key={`card-${index}`}
                                                        sx={{
                                                            position: "relative",
                                                            zIndex: 1,
                                                            width: "100%",
                                                            height: "100%",
                                                            display: currentPage === index ? "block" : "none",
                                                        }}
                                                    >

                                                        <IconButton
                                                            onClick={showImage}
                                                            sx={{
                                                                ...IconStyles,
                                                                top: "9%", 
                                                                right: "2%", 
                                                            }}
                                                        >
                                                            <ZoomInIcon/>
                                                        </IconButton>

                                                        <IconButton
                                                            onClick={handlePrevPage}
                                                            sx={{
                                                                ...IconStyles,
                                                                left: "2%", 
                                                            }}
                                                        >
                                                            <NavigateBeforeIcon/>
                                                        </IconButton>

                                                        <Slide direction={slideDirection} in={currentPage === index}>
                                                            <Stack
                                                                { ...combinedHandlers }
                                                                spacing={2}
                                                                direction="row"
                                                                alignContent="center"
                                                                justifyContent="center"
                                                                sx={{ width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)", cursor: "grab" }}
                                                            >
                                                                <div className='w-full aspect-video' style={{
                                                                    backgroundImage: `url(${thumbnail})`,
                                                                    backgroundSize: "cover",
                                                                    backgroundPosition: "center",
                                                                    backgroundRepeat: "no-repeat",
                                                                }}/>
                                                            </Stack>
                                                        </Slide>

                                                        <IconButton
                                                            onClick={handleNextPage}
                                                            sx={{
                                                                ...IconStyles,
                                                                right: "2%", 
                                                            }}
                                                        >
                                                            <NavigateNextIcon />
                                                        </IconButton>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                    )}
                                </Item>
                            </Grid>

                            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                                <Item>
                                    <Typography variant="h4">
                                        {vehicle?.make} {vehicle?.model}
                                    </Typography>
                                    { vehicle?.sell_price && (
                                        <Typography variant="h5">
                                            {(vehicle && vehicle.sell_price) ? `€${intToLocaleString(vehicle.sell_price)}` : t('N/A')}
                                        </Typography>
                                    )}                                    
                                        
                                    {/* <Typography variant="body1">
                                        {(vehicle && vehicle.notes && vehicle.notes.note && vehicle.notes.note[1] && vehicle.notes.note[1].description) ? vervangInString(convertHtmlToPlainText(vehicle.notes.note[1].description)) : t('N/A')}
                                    </Typography> */}
                                    {description !== '' && (
                                        <div dangerouslySetInnerHTML={{__html: description }} />
                                    )}

                                    <Button variant="contained" className="mt-5 hidden md:inline-block" href={`mailto:info@kegelsturnhout.be?subject=${t("Test Drive Request")} - ${vehicle?.make} ${vehicle?.model} `} >
                                        {t('Request Test Drive')}
                                    </Button>

                                </Item>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            <section className="bg-primary-grey py-10">
                <Container>
                    <Grid container spacing={2} className="">
                        {/* First registration */}
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <BounceAnimation tension={120} friction={12} delay={200}>
                                <Box className={carDetailClasses}>
                                    <Typography className="text-center">
                                        {t('First registration')}
                                    </Typography>
                                    <Typography variant="h6" className="pb-0">
                                        { (vehicle && vehicle.first_reg && !Number.isNaN(new Date(vehicle.first_reg).getTime())) ? toLocaleDate(vehicle.first_reg) : t('N/A') }
                                    </Typography>
                                </Box>
                            </BounceAnimation>
                        </Grid>

                        {/* Kilometers driven */}
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <BounceAnimation tension={120} friction={12} delay={300}>
                                <Box className={carDetailClasses}>
                                    <Typography className="text-center">
                                        {t('Kilometers driven')}
                                    </Typography>
                                    <Typography variant="h6" className="pb-0">
                                        {(vehicle && vehicle.mileage) ? `${intToLocaleString(vehicle.mileage)} KM` : t('N/A')}
                                    </Typography>
                                </Box>
                            </BounceAnimation>
                        </Grid>
                        
                        {/* Fuel type */}
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <BounceAnimation tension={120} friction={12} delay={400}>
                                <Box className={carDetailClasses}>
                                    <Typography className="text-center">
                                        {t('Fuel')}
                                    </Typography>
                                    <Typography variant="h6" className="pb-0">
                                        {(vehicle && vehicle.fuel_type) ? capitalize(t(vehicle.fuel_type)) : t('N/A')}
                                    </Typography>
                                </Box>
                            </BounceAnimation>
                        </Grid>

                        {/* Gears type */}
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <BounceAnimation tension={120} friction={12} delay={500}>
                                <Box className={carDetailClasses}>
                                    <Typography className="text-center">
                                        {t('Transmission')}
                                    </Typography>
                                    <Typography variant="h6" className="pb-0">
                                        {(vehicle && vehicle.gears_type) ? capitalize(vehicle.gears_type) : t('N/A')}
                                    </Typography>
                                </Box>
                            </BounceAnimation>
                        </Grid>

                        {/* Doors */}
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <BounceAnimation tension={120} friction={12} delay={600}>
                                <Box className={carDetailClasses}>
                                    <Typography className="text-center">
                                        {t('Doors')}
                                    </Typography>
                                    <Typography variant="h6" className="pb-0">
                                        {(vehicle && vehicle.doors) ? `${vehicle.doors}` : t('N/A')}
                                    </Typography>
                                </Box>
                            </BounceAnimation>
                        </Grid>

                        {/* Motor */}
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                            <BounceAnimation tension={120} friction={12} delay={700}>
                                <Box className={carDetailClasses}>
                                    <Typography className="text-center">
                                        {t('Engine')}
                                    </Typography>
                                    <Typography variant="h6" className="pb-0">
                                        {(vehicle && vehicle.hp && vehicle.kw) ? `${vehicle.hp} ${t('HP')} / ${vehicle.kw} ${t('kW')}` : (vehicle && vehicle.hp) ? `${vehicle.hp} ${t('HP')}` : (vehicle && vehicle.kw) ? `${vehicle.kw} ${t('kW')}` : t('N/A')}
                                    </Typography>
                                </Box>
                            </BounceAnimation>
                        </Grid>
                    </Grid>
                </Container>
            </section>
                
            
            
            <Container className="my-10">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5">
                        {t("Specifications")}
                    </Typography>
                </Grid>
                
                <DynamicColumns {...vehicle as Vehicle} />

                {/* <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Item className="flex items-center justify-between">
                            <Typography>
                                {t("First registration")}
                            </Typography>
                            <Typography variant="h6" className="p-0">
                                {(vehicle && vehicle.first_reg) ? new Date(vehicle.first_reg).toLocaleDateString(`${activeLanguage}-${activeLanguage.toUpperCase()}`) : t('N/A')}
                            </Typography>
                        </Item>
                        <Item className="flex items-center justify-between">
                            <Typography>
                                {t("Condition")}
                            </Typography>
                            <Typography variant="h6" className="p-0">
                                {(vehicle && vehicle.type) ? capitalize(vehicle.type) : t('N/A')}
                            </Typography>
                        </Item>
                        <Item className="flex items-center justify-between">
                            <Typography>
                                {t("Make")}
                            </Typography>
                            <Typography variant="h6" className="p-0">
                                {(vehicle && vehicle.make) ? capitalize(vehicle.make) : t('N/A')}
                            </Typography>
                        </Item>
                        <Item className="flex items-center justify-between">
                            <Typography>
                                {t("Model")}
                            </Typography>
                            <Typography variant="h6" className="p-0">
                                {(vehicle && vehicle.model) ? vehicle.model.toString() : t('N/A')}
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Item className="flex items-center justify-between">
                            <Typography>
                                {t("Doors")}
                            </Typography>
                            <Typography variant="h6" className="p-0">
                                {(vehicle && vehicle.doors) ? vehicle.doors : t('N/A')}
                            </Typography>
                        </Item>
                        <Item className="flex items-center justify-between">
                            <Typography>
                                {t("Fuel")}
                            </Typography>
                            <Typography variant="h6" className="p-0">
                                {(vehicle && vehicle.fuel_type) ? capitalize(t(vehicle.fuel_type)) : t('N/A')}
                            </Typography>
                        </Item>
                        <Item className="flex items-center justify-between">
                            <Typography>
                                {t("Kilowatt")}
                            </Typography>
                            <Typography variant="h6" className="p-0">
                                {(vehicle && vehicle.kw) ? `${vehicle.kw} ${t('kW')}` : t('N/A')}
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Item className="flex items-center justify-between">
                            <Typography>
                                {t("Transmission")}
                            </Typography>
                            <Typography variant="h6" className="p-0">
                                {(vehicle && vehicle.gears_type) ? capitalize(vehicle.gears_type) : t('N/A')}
                            </Typography>
                        </Item>
                        <Item className="flex items-center justify-between">
                            <Typography>
                                {t("Color")}
                            </Typography>
                            <Typography variant="h6" className="p-0">
                                {(vehicle && vehicle.bodyColorStandard) ? capitalize(vehicle.bodyColorStandard) : t('N/A')}
                            </Typography>
                        </Item>
                        <Item className="flex items-center justify-between">
                            <Typography>
                                {t("Power")}
                            </Typography>
                            <Typography variant="h6" className="p-0">
                                {(vehicle && vehicle.hp) ? `${vehicle.hp} ${t('HP')}` : t('N/A')}
                            </Typography>
                        </Item>
                    </Grid>
                </Grid> */}
                    
                <section className="w-full fixed bottom-0 z-[999] block md:hidden">
                    <Button variant="contained" color="primary" className="w-full fixed left-0 rounded-none text-lg font-normal p-3 bottom-0 normal-case" href={`mailto:info@kegelsturnhout.be?subject=${t("Test Drive Request")} - ${vehicle?.make} ${vehicle?.model} `} >
                        {t('Request Test Drive')}
                    </Button>
                </section>
            </Container>
        </React.Fragment>
    )
};

export default AanbodDetail;
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import MainLayout from "components/layouts/MainLayout";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import TagManager from 'react-gtm-module';

import { routes } from "routes";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchVehicles } from "redux/features/vehicleStateSlice";
import useLanguage from "hooks/useLanguage";

const baseSpacing = 6;
const mdSpacing = 2; // De gewenste spacing voor md en grotere schermen

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#264174',
    },
    // action: {
    //   active: '#f00',    // your color here
    //   hover: '#ff0', // your hover color here
    //   selected: '#0f0', // your selected color here
    // }, 
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 'bold',
        },
        h2: {
          fontWeight: 'bold',
        },
        h3: {
          fontWeight: 'bold',
        },
        h4: {
          fontWeight: 'bold',
        },
        h5: {
          fontWeight: 'bold',
        },
        h6: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // Hier stel je stijlen in voor de root van de MuiTextField
          marginTop: '0.7rem',
          marginBottom: '0.7rem',

          // Om de input borderkleur te wijzigen
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#222121', // Verander naar gewenste kleur
            },
            // '&:hover fieldset': {
            //   borderColor: 'rgba(255, 255, 255, 0.5)', // Verander naar gewenste hover kleur
            // },
            // '&.Mui-focused fieldset': {
            //   borderColor: '#fff', // Verander naar gewenste focus kleur
            // },
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          paddingLeft: baseSpacing, // Standaard padding voor Grid
          paddingRight: baseSpacing, // Standaard padding voor Grid
          [`@media (min-width:${960}px)`]: {
            paddingLeft: mdSpacing, // Specifieke padding voor md en grotere schermen
            paddingRight: mdSpacing, // Specifieke padding voor md en grotere schermen
          },
        },
      },
    },
  },
});


function App() {
    const dispatch = useAppDispatch();
    const Vehicles = useAppSelector((state) => state.vehicleStateSlice.vehicles);
    if (Vehicles.length <= 0) {
        dispatch(fetchVehicles());
    }

    const { activeLanguage, t } = useLanguage();

    React.useEffect(() => {
        document.documentElement.lang = activeLanguage;
    }, []);
    
    React.useEffect(() => {
        const tagManagerArgs = {
            gtmId: 'GTM-PNMGBJ6F'
        };
        TagManager.initialize(tagManagerArgs);
    }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path={`${activeLanguage ?? 'nl'}/`} element={<Outlet />}>
            {routes}
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;

import * as React from 'react';

// elements
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import SliderElement from 'components/elements/Filter/Slider';
import Checkboxes from 'components/elements/Filter/Checkboxes';
import CheckboxGroup from 'components/elements/Filter/CheckboxGroup';

import { useAppSelector } from 'redux/hooks';
import useLanguage from 'hooks/useLanguage';

interface Props {
    allowMultiple?: boolean;
}

// Accordion Wrapper
const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));
// Accordion Summary Wrapper
const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));
// Accordion Details Wrapper
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function VehicleFilterAccordeon({ allowMultiple = false }: Props) {
    const { t } = useLanguage();


    const FilterOptions = useAppSelector((state) => state.vehicleStateSlice.filterOptions);

    // expand functions for single
    const [ExpandedSingle, setExpandedSingle] = React.useState<string | false>('panel0');
    const handleExpandSingle = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedSingle(isExpanded ? panel : false);
    };
    // expand functions for multiple
    const [ExpandedMultiple, setExpandedMultiple] = React.useState<string[]>([
        'panel0',
    ]);
    const handleExpandMultiple = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        if (isExpanded) {
            setExpandedMultiple(prevExpanded => [...prevExpanded, panel]);
        } else {
            setExpandedMultiple(prevExpanded => prevExpanded.filter(x => x !== panel));
        }
    };
    // set the correct expand function
    const handleExpand = allowMultiple ? handleExpandMultiple : handleExpandSingle;

    const minMaxYear = FilterOptions.yearRange;
    const minMaxPrice = FilterOptions.priceRange;
    const minMaxMileage = FilterOptions.mileageRange;
    const minMaxEmission = FilterOptions.emissionRange;
    const AllMakes = FilterOptions.makes;
    const AllFuels = FilterOptions.fuels;
    const AllGearTypes = FilterOptions.gearTypes;
    const AllBodyTypes = FilterOptions.bodyTypes;

    const panels = [
        { title: t('Make and model'), content: <CheckboxGroup id='filter-make' makes={AllMakes} /> },
        { title: t('Year'), content: <SliderElement id='filter-year' label='Year' min={minMaxYear.min} max={minMaxYear.max} type='year' /> },
        { title: t('Price'), content: <SliderElement id='filter-price' label='Price' min={minMaxPrice.min} max={minMaxPrice.max} type='price' /> },
        { title: t('Mileage'), content: <SliderElement id='filter-mileage' label='Mileage' min={minMaxMileage.min} max={minMaxMileage.max} type='mileage' /> },
        { title: t('CO2'), content: <SliderElement id='filter-emission' label='CO2' min={minMaxEmission.min} max={minMaxEmission.max} type='emission' /> },
        { title: t('Fuel'), content: <Checkboxes id='filter-fuel' label='Fuel' options={AllFuels} /> },
        { title: t('Transmission'), content: <Checkboxes id='filter-transmission' label='Transmission' options={AllGearTypes} /> },
        { title: t('Body type'), content: <Checkboxes id='filter-body' label='Body type' options={AllBodyTypes} /> },
    ];

    return (
        <div>
            {panels.map((panel, index) => (
                <Accordion
                    key={`panel${index}`}
                    className={index === 0 ? 'mt-4 mb-1' : 'mb-1'}
                    expanded={(allowMultiple) ? ExpandedMultiple.includes(`panel${index}`) : ExpandedSingle === `panel${index}`} 
                    onChange={handleExpand(`panel${index}`)}
                >
                    <AccordionSummary aria-controls={`${`panel${index}`}-content`} id={`${`panel${index}`}-header`} className='bg-primary-grey'>
                        <Typography variant="body1">{panel.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {panel.content}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}
import React from "react";
import Homepage from "pages/home/homepage";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";

import { RouteType } from "./config";
// import Aanbod from "pages/Aanbod/Aanbod";
import Aanbod from "pages/Aanbod/Aanbod";
import AanbodDetail from "pages/AanbodDetail/AanbodDetail";
import Cookies from "pages/Cookies/Cookies";
import WettelijkeBepalingen from "pages/WettelijkeBepalingen/WettelijkeBepalingen";
import NotFound from "pages/NotFound/NotFound";

const curPath = window.location.pathname;
const lang = curPath.split('/')[1];
const activeLanguage = lang;

const Routes: RouteType[] = [
  {
    path: `/${activeLanguage ?? 'nl'}/`,
    displayText: "Home",
    element: <Homepage />,
    state: "default",
    showInMenu: false
  },
  {
    path: `/${activeLanguage ?? 'nl'}/#root`,
    displayText: "Home",
    element: <Homepage />,
    state: "default",
    showInMenu: true
  },
  {
    path: `/${activeLanguage ?? 'nl'}/offers`,
    displayText: "Offers",
    element: <Aanbod />,
    state: "aanbod",
    showInMenu: true
  },
  {
    path: `/${activeLanguage ?? 'nl'}/offers/:id`,
    displayText: "Aanbod detail",
    element: <AanbodDetail />,
    state: "aanbod-detail",
    showInMenu: false
  },
  {
    path: `/${activeLanguage ?? 'nl'}/#contact`,
    displayText: "Contact",
    element: <Homepage />,
    state: "default",
    showInMenu: true
  },
  {
    path: `/${activeLanguage ?? 'nl'}/privacy-policy`,
    displayText: "Privacy Policy",
    element: <PrivacyPolicy />,
    state: "privacy-policy",
    showInMenu: false
  },
  {
    path: `/${activeLanguage ?? 'nl'}/cookies`,
    displayText: "Cookies",
    element: <Cookies />,
    state: "cookies",
    showInMenu: false
  },
  {
    path: `/${activeLanguage ?? 'nl'}/legal-provisions`,
    displayText: "Wettelijke Bepalingen",
    element: <WettelijkeBepalingen />,
    state: "wettelijke-bepalingen",
    showInMenu: false
  },
  {
    path: `/${activeLanguage ?? 'nl'}/*`,
    displayText: "Not Found",
    element: <NotFound />,
    state: "default",
    showInMenu: false
  }
];

export default Routes;
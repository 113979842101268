import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { browserName } from 'react-device-detect';

import Topbar from "components/common/Topbar";
import { routes } from "routes";
import Footer from "components/common/Footer";

import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import useLanguage from "hooks/useLanguage";

const MainLayout = () => {
    const { activeLanguage, t } = useLanguage();

    const [showPopup, setShowPopup] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const user = { id: 1, name: "" };
    const popupShownKey = `popupShown_${user.id}`;

    useEffect(() => {
        const hasShownPopup = localStorage.getItem(popupShownKey);
        
        if (!hasShownPopup) {
            setShowPopup(true);
        }
    }, []);

    const handleClosePopup = () => {
        setShowPopup(false);
        localStorage.setItem(popupShownKey, 'true');
    };


  return (
    <Box className={`${browserName} overflow-hidden`}>
      <Box
        component="nav"
        sx={{
          flexShrink: 0,
          height: 65,
          zIndex:1
        }}
      >
        <Topbar collection={routes} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          top: 65,
          minHeight: "100vh",
          zIndex: 1,
        }}
      >
              
              {/* <Dialog
                disableEscapeKeyDown={true}
                open={showPopup}
                onClose={handleClosePopup}
                maxWidth="sm"
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    {t("Adjusted opening hours")}
                    <IconButton
                    aria-label="close"
                    onClick={handleClosePopup}
                    sx={{
                        position: 'absolute',
                        right: isMobile ? 1 : 8,
                        top: isMobile ? 1 : 8,
                        color: 'black',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
                <DialogContent className="p-6">
                    <Typography variant="body1" className="text-sm">
                        {t("Due to the summer holidays, adjusted opening hours apply.")}
                      </Typography>
                      <br/>
                    <Typography variant="body1" className="text-sm">
                        {t("12/08 to 14/08: workshop standby - showroom open")}
                    </Typography>
                    <Typography variant="body1" className="text-sm mb-4">
                        {t("15/08 to 18/08: workshop closed - showroom closed")}
                    </Typography>
                </DialogContent>
            </Dialog> */}


              <Outlet />
      </Box>
      <Box
        component="footer"
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default MainLayout;
import { Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useLanguage from "hooks/useLanguage";
import useMeta from "components/useMeta";
import AanbodSlider from "components/common/AanbodSlider";
import BannerSlider from "components/common/BannerSlider";

import { BounceAnimation, MoveFadeInAnimation } from "hooks/useAnimations";

const Homepage = () => {
    const { activeLanguage, t } = useLanguage();
    
    const CallSales = [
        {
            name:"Monday",
            time: "09:00 - 18:00"
        },
        {
            name: "Tuesday",
            time: "09:00 - 18:00"
        },
        {
            name: "Wednesday",
            time: "09:00 - 18:00"
        },
        {
            name: "Thursday",
            time: "09:00 - 18:00"
        },
        {
            name: "Friday",
            time: "09:00 - 18:00"
        },
        {
            name: "Saturday",
            time: "10:00 - 16:00"
        },
        {
            name: "Sunday",
            time: "Closed"
        },
        {
            name: "Public Holidays",
            time: "Closed"
        }
    ];
    
    const CallAfterSales = [
        {
            name:"Monday",
            time: "08:15 - 12:15 & 13:00 - 17:00"
        },
        {
            name: "Tuesday",
            time: "08:15 - 12:15 & 13:00 - 17:00"
        },
        {
            name: "Wednesday",
            time: "08:15 - 12:15 & 13:00 - 17:00"
        },
        {
            name: "Thursday",
            time: "08:15 - 12:15 & 13:00 - 17:00"
        },
        {
            name: "Friday",
            time: "08:15 - 12:15 & 13:00 - 15:00"
        },
        {
            name: "Saturday",
            time: "Closed"
        },
        {
            name: "Sunday",
            time: "Closed"
        },
        {
            name: "Public Holidays",
            time: "Closed"
        }
    ];

    useEffect(() => {
        if (window.location.hash === '#root') {
            // Update de URL zonder te herladen
            window.history.replaceState(null, '', ' ');
        }
    }, []);

    return (
        useMeta({
            title: 'Kegels Turnhout - Home',
            description: t("Kegels Turnhout is an official Stellantis sales point in TURNHOUT for the brands Jeep, Fiat, Alfa Romeo, Abarth, Fiat Professional."),
            keywords: '',
        }),
        <React.Fragment>
            <section className="w-full min-h-[40vh] max-h-[700px] overflow-hidden relative bg-[url('../public/images/garage-kegels-header-1.jpg')] bg-cover bg-bottom">
                <div className="bg-slate-100 bg-opacity-80 bottom-0 absolute w-full py-4">
                    <Container>
                        <Grid container spacing={3} className="flex items-center justify-center">
                      
                            <Grid item xs={2} sm={2} md={2}>
                                <BounceAnimation tension={120} friction={12} delay={0}>
                                    <img className="h-auto md:h-16 mx-auto" src="/images/brands/brand-4.png" alt="Alfa Romeo" />
                                </BounceAnimation>
                            </Grid>
                            
                            <Grid item xs={2} sm={2} md={2}>
                                <BounceAnimation tension={120} friction={12} delay={150}>
                                    <img className="h-auto md:h-16 mx-auto" src="/images/brands/brand-1.png" alt="Jeep" />
                                </BounceAnimation>
                            </Grid>
               
                            <Grid item xs={2} sm={2} md={2}>
                                <BounceAnimation tension={120} friction={12} delay={300}>
                                    <img className="h-auto md:h-16 mx-auto" src="/images/brands/brand-5.png" alt="Fiat" />
                                </BounceAnimation>
                            </Grid>

                            <Grid item xs={2} sm={2} md={2}>
                                <BounceAnimation tension={120} friction={12} delay={450}>
                                    <img className="h-auto md:h-16 mx-auto" src="/images/brands/brand-2.png" alt="Abarth" />
                                </BounceAnimation>
                            </Grid>
                    
                            <Grid item xs={2} sm={2} md={2}>
                                <BounceAnimation tension={120} friction={12} delay={600}>
                                    <img className="h-auto md:h-16 mx-auto" src="/images/brands/brand-3.png" alt="Fiat" />
                                </BounceAnimation>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </section>

            <section className="bg-white py-8">
                <Container>
                    <Grid container spacing={3} className="my-20 flex items-center justify-center text-center">
                        <Grid item xs={12} sm={12} md={10} lg={8}>
                            <MoveFadeInAnimation>
                                <Typography variant="h1" className="font-bold">
                                    {t("Welcome to Kegels")}
                                </Typography>
                            </MoveFadeInAnimation>
                            <MoveFadeInAnimation delay={300}>
                                <Typography variant="body1" className="">
                                    {t("Step inside - and drive outside - at the official retailer for the Jeep, Alfa Romeo, Fiat / Abarth and Fiat Professional brands. You can always contact us for new cars, quality used cars and for the takeover of your current vehicle. At Kegels we aim for professional care from A to Z. After all, Belgian traffic is stressful enough as it is. Can we welcome you warmly soon?")}
                                </Typography>
                            </MoveFadeInAnimation>
                        </Grid>
                    </Grid>
                </Container>

                <Container>
                    <Grid container spacing={3} className="flex items-center justify-center text-center">
                        <Grid item xs={12} sm={12} md={10} lg={8}>
                            <Typography variant="h4" className="font-bold">
                                {t("Contact")}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className="lg:text-right">
                            <div className="flex gap-8 justify-center">
                                <Button variant="contained" href={`mailto:info@kegelsturnhout.be`} >
                                    {t("Email us")}
                                </Button>
                                <Button variant="contained" href={`tel:3214450311`} >
                                    {t("Call us")}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                
                <Container className="-mb-[20%]">
                    <Grid container spacing={3} className="my-14">
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <BannerSlider id={'promotional-slider'} />
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <Container className="pt-[20%] py-8">    
                <Grid container spacing={3} className="my-16 flex items-center justify-center text-center">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <MoveFadeInAnimation>
                            <Typography variant="h4" className="font-bold">
                                {t("Our Offer")}
                            </Typography>
                        </MoveFadeInAnimation>
                    </Grid>
                        
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <AanbodSlider id={1} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button variant="contained" href={`/${activeLanguage}/offers`} >
                            {t("Click here for more")}
                        </Button>
                    </Grid>
                </Grid>
            </Container>

            <section className="mt-[300px]">
                <div className="bg-white pt-[300px]">
                    <Container className="-mt-[600px] pb-4">
                        <Grid container spacing={3} className="my-16 flex items-center justify-center">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <MoveFadeInAnimation>
                                    <Typography variant="h4" className="font-bold text-center">
                                        {t("Our Team")}
                                    </Typography>
                                </MoveFadeInAnimation>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={7} className="pb-20">
                                <BounceAnimation delay={0}>
                                    <img className="w-full" src="/images/kegelspersoneel.png" alt="kegels personeel" loading="lazy" />
                                </BounceAnimation>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </section>

            <section  className="contact-section overflow-hidden w-full">
                <Container id="contact" className="w-full px-0 md:px-5">
                    <Grid container spacing={0} className="w-full px-0 md:px-3">
                        <Grid item xs={12} sm={12} md={9} lg={9} className="ml-auto bg-primary-light-grey drop-shadow-xl rounded-t-xl h-full w-full mt-40 py-8 px-4 min-h-[500px] max-w-[700px]">
                            <div className="mb-4">
                                <Typography variant="h4" className="font-bold">
                                    {t("Contact")}
                                </Typography>
                                
                                <Typography variant="body1" className="mb-2">
                                    Turnhout - Ambachtstraat 2, 2300 Turnhout
                                </Typography>
                                <div className="flex gap-2">
                                    <Typography variant="body1" className="">
                                        {t("Call us:")}
                                    </Typography>
                                    <Link className="underline" to="tel:3214450311">
                                        +32 14 450311
                                    </Link>
                                </div>
                            </div>
                            
                            <Grid container spacing={3} className="">
                                <Grid item xs={12} sm={6} className="">
                                    <Typography variant="h6" className="w-full">
                                    {t("Sales")}:
                                    </Typography>
                                    { CallSales.map((item, index) =>
                                        <Grid key={index} container spacing={3} className="">
                                            <Grid item xs={4} className="">
                                                <Typography variant="body1" className="text-sm">
                                                    {t(item.name)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} className="">
                                                <Typography variant="body1" className="text-sm font-normal">
                                                    {t(item.time)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}  className="">
                                    <Typography variant="h6" className="w-full">
                                        {t("After-Sales")}:
                                    </Typography>
                                    { CallAfterSales.map((item, index) =>
                                        <Grid key={index} container spacing={3} className="">
                                            <Grid item xs={4} className="">
                                                <Typography variant="body1" className="text-sm">
                                                    {t(item.name)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} className="">
                                                <Typography variant="body1" className="text-sm font-normal">
                                                    {t(item.time)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            
        </React.Fragment>
    );
}
 
export default Homepage;
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { Link, useNavigate } from 'react-router-dom';

import useLanguage from 'hooks/useLanguage';
import { Vehicle } from 'redux/features/VehiclesTypes';

import { BsFillFuelPumpFill } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import { IoIosSpeedometer } from "react-icons/io";

import useSwipe from 'helpers/useSwipe';
import useDrag from 'helpers/useDrag';

import { capitalize, toLocaleDate, intToLocaleString, slugify } from 'helpers/stringHelper';

type Props = {
    internalId: number;
    make: string;
    model: string | number;
    price: number;
    thumbnails: string[];
    data: Vehicle;
    hasThumbnailSlider?: boolean;
}

export default function VehicleCard({ internalId, make, model, price, thumbnails, data, hasThumbnailSlider = false }: Props) {
    const { activeLanguage, t } = useLanguage();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(0);
    const [slideDirection, setSlideDirection] = useState<"right" | "left" | undefined>("left");

    const IconStyles = {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 2,
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "white",
        borderRadius: "50%",
        cursor: "pointer",
        ":hover": {
            backgroundColor: "rgba(0,0,0,0.5)",
        },
        ":disabled": {
            backgroundColor: "rgba(0,0,0,0.4)",
            color: "rgba(255,255,255,0.5)",
        },
    }

    const handleNextPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        nextPage();
    };
    
    const nextPage = () => {
        setSlideDirection("left");
        if (currentPage >= thumbnails.length - 1) {
            setCurrentPage(0);
        } else {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }
    
    const handlePrevPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        prevPage();
    };

    const prevPage = () => {
        setSlideDirection("right");
        if (currentPage === 0) {
            setCurrentPage(thumbnails.length - 1);
        } else {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    }

    const goToDetail = () => {
        navigate(`/${activeLanguage ?? 'nl'}/offers/${slugify(`${make} ${model}`)}?id=${internalId}`);
    }

    const swipeHandlers = useSwipe({ onSwipedLeft: () => nextPage(), onSwipedRight: () => prevPage(), onClickFallback: goToDetail});
    const dragHandlers = useDrag({ onDragLeft: (e) => prevPage(), onDragRight: (e) => nextPage(), onClickFallback: goToDetail });

    const combinedHandlers = {
        ...swipeHandlers,
        ...dragHandlers,
    };

    return (
        <Card className='select-none h-full shadow-none rounded-lg hover:rounded-xl drop-shadow-md transition lg:hover:drop-shadow-lg lg:hover:scale-105'>
            { thumbnails.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                        height: "auto",
                        width: "100%",
                    }}
                >
                    {hasThumbnailSlider ? (
                        <Box sx={{ width: `100%`, height: "100%" }}>
                            {(thumbnails.length > 0) && thumbnails.map((thumbnail, index) => (
                                <Box
                                    key={`card-${index}`}
                                    sx={{
                                        position: "relative",
                                        zIndex: 1,
                                        width: "100%",
                                        height: "100%",
                                        display: currentPage === index ? "block" : "none",
                                    }}
                                >

                                    <IconButton
                                        id={`prev-button-${index}`}
                                        onClick={handlePrevPage}
                                        sx={{
                                            ...IconStyles,
                                            left: "2%", 
                                        }}
                                    >
                                        <NavigateBeforeIcon/>
                                    </IconButton>

                                    <Slide direction={slideDirection} in={currentPage === index}>
                                        <Stack
                                            {...combinedHandlers}
                                            spacing={2}
                                            direction="row"
                                            alignContent="center"
                                            justifyContent="center"
                                            sx={{ width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)" }}
                                        >
                                            <div className='w-full aspect-video cursor-grab' style={{
                                                backgroundImage: `url(${thumbnail})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat",
                                            }}/>
                                        </Stack>
                                    </Slide>

                                    <IconButton
                                        id={`next-button-${index}`}
                                        onClick={handleNextPage}
                                        sx={{
                                            ...IconStyles,
                                            right: "2%", 
                                        }}
                                    >
                                        <NavigateNextIcon  />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        (thumbnails.length > 0) && (
                            <Box onClick={goToDetail} sx={{ width: `100%`, height: "100%", cursor: "pointer" }}>
                                    <div className='w-full aspect-video' style={{
                                        backgroundImage: `url(${thumbnails[0]})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                    }}/>
                            </Box>
                        )
                    )}
                </Box>
            )}
            <Link className='cursor-pointer' to={`/${activeLanguage ?? 'nl'}/offers/${slugify(`${make} ${model}`)}?id=${internalId}`}>
                <CardContent>
                    <Typography variant="h6" className='truncate'>
                        {make} {model}
                    </Typography>
                    
                    <Grid container>
                        <Grid item xs={6} md={12} lg={6} className='min-w-[80px]'>
                            {/* bouwjaar */}
                            <div className='flex gap-2 items-center pb-1'>
                                <FaCalendarAlt size={15} />
                                <Typography variant="body2" >
                                    { (data && data.first_reg && !Number.isNaN(new Date(data.first_reg).getTime())) ? toLocaleDate(data.first_reg) : t('N/A') }
                                </Typography>
                            </div>

                            {/* kilometers */}
                            <div className='flex gap-2 items-center'>
                                <IoIosSpeedometer size={15} />
                                <Typography variant="body2" >
                                    { (data && data.mileage) ? intToLocaleString(data.mileage) + ' KM' : t('N/A') }
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} md={12} lg={6}>
                            {/* brandstof */}
                            <div className='flex gap-2 items-center pb-1'>
                                <BsFillFuelPumpFill size={15} />
                                <Typography variant="body2" >
                                    {(data && data.fuel_type) ? capitalize(t(data.fuel_type)) : t('N/A')}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>

                </CardContent>
                <CardActions disableSpacing className='p-0 mb-0 mt-auto'>
                    <Grid container className='p-0 m-0'>
                        <Grid item xs={6} className='bg-primary-light-grey px-2 py-2 flex justify-center items-center'>
                            <Typography variant="body1" className='leading-2 pb-0 mb-0 text-md text-center font-semibold' >
                                € {intToLocaleString(price)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className='bg-primary-grey px-2 py-2'>
                            <Typography className='cursor-pointer w-full leading-2 pb-0 mb-0 text-md text-center font-semibold block'>
                                {t("Read more")}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActions>
            </Link>
            {/* <CardActions disableSpacing>
                <Grid className='w-full'>
                    <Button variant="contained" className='w-full m-auto' >
                        {t("View this car")}
                    </Button>
                </Grid>
            </CardActions> */}
        </Card>
    );
}
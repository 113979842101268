import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import vehicleService from "services/vehicleService";
import { Detail, EmissionRange, EquipmentDetailsClass, FilterMake, FilterYearRange, MileageRange, Note, Notes, PriceRange, Vehicle } from "./VehiclesTypes";


export interface FilterCriteria {
  [key: string]: any;
  first_reg_min?: number;
  first_reg_max?: number;
  sell_price_min?: number;
  sell_price_max?: number;
  co2_min?: number;
  co2_max?: number;
  mileage_min?: number;
  mileage_max?: number;

  make?: string[];
  model?: string[];
  fuel_type?: string[];
  gears_type?: string[];
  body_type?: string[];
}

export interface FilterOptions {
  [key: string]: any;
  yearRange: FilterYearRange;
  emissionRange: EmissionRange;
  priceRange: PriceRange;
  mileageRange: MileageRange;


  makes: FilterMake[];
  fuels: string[];
  gearTypes: string[];
  bodyTypes: string[];
}

interface VehicleState {
  vehicles: Vehicle[];
  filteredVehicles: Vehicle[];
  filterCriteria: FilterCriteria;
  filterOptions: FilterOptions;
  isLoading: boolean;
}

const initialState: VehicleState = {
  vehicles: [],
  filteredVehicles: [],
  filterCriteria: {},
  filterOptions: {
    yearRange: { min: 0, max: 0 },
    priceRange: { min: 0, max: 0 },
    emissionRange: { min: 0, max: 0 },
    mileageRange: { min: 0, max: 0 },
    makes: [],
    fuels: [],
    gearTypes: [],
    bodyTypes: [],
  },
  isLoading: true,
};

export const fetchVehicles = createAsyncThunk('vehicles/fetch', async (thunkAPI) => {
  const curPath = window.location.pathname;
  const lang = curPath.split('/')[1];
  const activeLanguage = lang;

  interface LangEnums {
    [key: string]: number | string;
  }

  const LangEnums:any = {
    'fr': 1,
    'en': 6,
    'nl': 7,
    'de': 8,
  };
  const langId = LangEnums[activeLanguage as string];
  

  const response = await vehicleService.getVehicles();

  const data = response.map((item: Vehicle) => {
    let equipmentDetailsTranslated: string[] = [];
    if (item.equipment_details && item.equipment_details.details) {
      equipmentDetailsTranslated = item.equipment_details.details.filter(detail => detail.langue === `${langId}` || detail.langue === langId).map(detail => detail.desc);
    }

    return {
      internal_id: item.internal_id,
      type: item.type,
      make: item.make,
      model: item.model,
      doors: item.doors,
      body_type: item.body_type,
      motorisation: item.motorisation,
      engine_size: item.engine_size,
      fuel_type: item.fuel_type,
      kw: item.kw,
      hp: item.hp,
      notes: item.notes,
      gears: item.gears,
      gears_type: item.gears_type,
      transmission: item.transmission,
      paint_type: item.paint_type,
      interiorColor: item.interiorColor,
      bodyColorStandard: item.bodyColorStandard,
      seats: item.seats,
      number_of_seats: item.number_of_seats,
      first_reg: item.first_reg,
      datec2: item.datec2,
      co2: item.co2,
      co2WLTP: item.co2WLTP,
      electricCV: item.electricCV,
      featured: item.featured,
      mileage: item.mileage,
      sell_price: item.sell_price,
      buy_price: item.buy_price,
      arrival_date: item.arrival_date,
      reserved: item.reserved,
      images: item.images,
      original_images: item.original_images,
      equipment_details_translated: equipmentDetailsTranslated,
    }
  }) as Vehicle[];
  return data;
});

const filterVehicles = (stateVehicles: Vehicle[], filter: FilterCriteria) => {
  let filteredVehicles = stateVehicles;
      
  if (Object.keys(filter).length === 0) {
    return filteredVehicles;
  }


  const processedFilter: FilterCriteria = {
    ...filter,
    make: filter.make?.map(item => item.toLowerCase()),
    model: filter.model?.map(item => item.toLowerCase()),
    fuel_type: filter.fuel_type?.map(item => item.toLowerCase()),
    gears_type: filter.gears_type?.map(item => item.toLowerCase()),
    body_type: filter.body_type?.map(item => item.toLowerCase()),
  };

  filteredVehicles = stateVehicles.filter(car => {
    let first_reg_year = 0;
    if (processedFilter.first_reg_min || processedFilter.first_reg_max) {
      first_reg_year = new Date(car.first_reg).getFullYear();
    }
    
    const carMake = car.make.toLowerCase();
    const carModel = car.model.toString().toLowerCase();
    const carFuelType = car.fuel_type.toLowerCase();
    const carGearType = car.gears_type.toLowerCase();

    return (
      (!processedFilter.make || processedFilter.make == null || processedFilter.make.includes(carMake))
      &&
      (!processedFilter.model || processedFilter.model.includes(carModel))
      &&
      (!processedFilter.sell_price_min || car.sell_price >= processedFilter.sell_price_min)
      &&
      (!processedFilter.sell_price_max || car.sell_price <= processedFilter.sell_price_max)
      &&
      (!processedFilter.fuel_type || processedFilter.fuel_type.includes(carFuelType))
      &&
      (!processedFilter.first_reg_min || first_reg_year >= processedFilter.first_reg_min)
      &&
      (!processedFilter.first_reg_max || first_reg_year <= processedFilter.first_reg_max)
      &&
      (!processedFilter.mileage_min || car.mileage >= processedFilter.mileage_min)
      &&
      (!processedFilter.mileage_max || car.mileage <= processedFilter.mileage_max)
      &&
      (!processedFilter.gears_type || processedFilter.gears_type.includes(carGearType))
      &&
      (!processedFilter.body_type || processedFilter.body_type.includes(car.body_type.toLowerCase()))
    );
  });

  return filteredVehicles;
}

const applyVehicleFilter = (state: VehicleState) => {
  // state.isLoading = true;
  const filteredVehicles = filterVehicles(state.vehicles, state.filterCriteria);
  state.filteredVehicles = filteredVehicles;
  // state.isLoading = false;
};

export const vehicleStateSlice = createSlice({
  name: "vehicleState",
  initialState,
  reducers: {

    setRangeFilter: (state, action: PayloadAction<{ range: { min: number, max: number }, xmlKey: string, filterOptionsKey: string }>) => {
      const minKey = `${action.payload.xmlKey}_min`;
      const maxKey = `${action.payload.xmlKey}_max`;

      const filterOptionsKey = `${action.payload.filterOptionsKey}Range`;

      if (action.payload.range.min === state.filterOptions[filterOptionsKey].min && action.payload.range.max === state.filterOptions[filterOptionsKey].max) {
        delete state.filterCriteria[minKey];
        delete state.filterCriteria[maxKey];
      } else {
        state.filterCriteria[minKey] = action.payload.range.min;
        state.filterCriteria[maxKey] = action.payload.range.max;
      }
    
      applyVehicleFilter(state);
    },

    setSelectedMakesAndModels: (state, action: PayloadAction<{ makes: string[], models: string[] }>) => {
      const { makes, models } = action.payload;
      state.filterCriteria.make = makes.length ? makes : undefined;
      state.filterCriteria.model = models.length ? models : undefined;
      
      applyVehicleFilter(state);
    },

    setSelectedFuelTypes: (state, action: PayloadAction<{ fuels: string[] }>) => {
      const { fuels } = action.payload;
      state.filterCriteria.fuel_type = fuels.length ? fuels : undefined;
      
      applyVehicleFilter(state);
    },

    setSelectedTransmissionTypes: (state, action: PayloadAction<{ gearTypes: string[] }>) => {
      const { gearTypes } = action.payload;
      state.filterCriteria.gears_type = gearTypes.length ? gearTypes : undefined;
      
      applyVehicleFilter(state);
    },

    setSelectedBodyTypes: (state, action: PayloadAction<{ bodyTypes: string[] }>) => {
      const { bodyTypes } = action.payload;
      state.filterCriteria.body_type = bodyTypes.length ? bodyTypes : undefined;
      
      applyVehicleFilter(state);
    },
  },
  extraReducers: (builder) => {

    builder.addCase(fetchVehicles.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchVehicles.fulfilled, (state, action) => {
      const vehicles = action.payload;

      // -------------------------------------------------------------------- 
      // Year options
      // -------------------------------------------------------------------- 
      const allYears = vehicles.map(item => {
        return item.first_reg ? parseInt(item.first_reg.split('-')[0]) : 1500;
      }).filter(year => year !== null);

      // --------------------------------------------------------------------
      // Price options
      // --------------------------------------------------------------------
      const allPrices = vehicles.map(item => {
        return item.sell_price;
      }).filter(price => price !== null);

      // --------------------------------------------------------------------
      // Mileage options
      // --------------------------------------------------------------------
      const allMileages = vehicles.map(item => {
        return item.mileage;
      }).filter(mileage => mileage !== null);

      // --------------------------------------------------------------------
      // Emission options
      // --------------------------------------------------------------------
      const allEmissions = vehicles.map(item => {
        return item.co2;
      }).filter(emission => emission !== null);



      // -------------------------------------------------------------------- 
      // Make and model options
      // -------------------------------------------------------------------- 
      const uniqueMakes = new Set();
      vehicles.forEach(item => {
          uniqueMakes.add(item.make as string);
      });

      const allMakes: FilterMake[] = [];
      uniqueMakes.forEach(make => {
          const models = new Set();
          vehicles.forEach(item => {
              if (item.make === make) {
                  models.add(item.model.toString() as string);
              }
          });
          allMakes.push({ name: make as string, models: Array.from(models) as string[] });
      });

      // --------------------------------------------------------------------
      // Fuel options
      // --------------------------------------------------------------------

      const uniqueFuels = new Set();
      vehicles.forEach(item => {
        uniqueFuels.add(item.fuel_type as string);
      });

      const fuels: string[] = [];
      uniqueFuels.forEach(fuel => {
        fuels.push(fuel as string);
      });


      // --------------------------------------------------------------------
      // Transmission options
      // --------------------------------------------------------------------

      const uniqueGearTypes = new Set();
      vehicles.forEach(item => {
        uniqueGearTypes.add(item.gears_type as string);
      });

      const gearTypes: string[] = [];
      uniqueGearTypes.forEach(gearType => {
        gearTypes.push(gearType as string);
      });
      

      // --------------------------------------------------------------------
      // Body options
      // --------------------------------------------------------------------

      const uniqueBodyTypes = new Set();
      vehicles.forEach(item => {
        uniqueBodyTypes.add(item.body_type as string);
      });

      const bodyTypes: string[] = [];
      uniqueBodyTypes.forEach(bodyType => {
        bodyTypes.push(bodyType as string);
      });

      

      
      // --------------------------------------------------------------------
      // set all the state values
      // --------------------------------------------------------------------
      state.filterOptions = {
        yearRange: { min: Math.min(...allYears), max: Math.max(...allYears) },
        priceRange: { min: Math.min(...allPrices), max: Math.max(...allPrices) },
        mileageRange: { min: Math.min(...allMileages), max: Math.max(...allMileages) },
        emissionRange: { min: Math.min(...allEmissions), max: Math.max(...allEmissions) },
        makes: allMakes,
        fuels: fuels,
        gearTypes: gearTypes,
        bodyTypes: bodyTypes,
      }
      
      state.vehicles = vehicles;
      state.filteredVehicles = vehicles;
      state.isLoading = false;
    });
  }
});

export const {
  // ranges
  setRangeFilter,

  // arrays
  setSelectedMakesAndModels,
  setSelectedFuelTypes,
  setSelectedTransmissionTypes,
  setSelectedBodyTypes,
} = vehicleStateSlice.actions;
export default vehicleStateSlice.reducer;
import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';
import { useEffect } from 'react';

import { useAppDispatch } from 'redux/hooks';
import { setRangeFilter } from 'redux/features/vehicleStateSlice';
import useLanguage from 'hooks/useLanguage';

interface Props {
    id: string;
    label: string;
    min: number;
    max: number;
    type: "price" | "mileage" | "year" | "emission";
}

export default function SliderElement({ id, label, min, max, type }: Props) {
    const { activeLanguage } = useLanguage();
    const dispatch = useAppDispatch();
    
    const [value, setValue] = React.useState<number[]>([min, max]);
    const debounceRef = React.useRef<number>();

    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);

        const value = newValue as number[];

        clearTimeout(debounceRef.current);
        debounceRef.current = window.setTimeout(() => {
            switch (type) {
                case "price":
                    dispatch(setRangeFilter({ xmlKey: 'sell_price', filterOptionsKey: 'price', range: { min: value[0], max: value[1] } }));
                    break;
                case "mileage":
                    dispatch(setRangeFilter({ xmlKey: 'mileage', filterOptionsKey: 'mileage', range: { min: value[0], max: value[1] } }));
                    break;
                case "year":
                    dispatch(setRangeFilter({ xmlKey: 'first_reg', filterOptionsKey: 'year', range: { min: value[0], max: value[1] } }));
                    break;
                case "emission":
                    dispatch(setRangeFilter({ xmlKey: 'co2', filterOptionsKey: 'emission', range: { min: value[0], max: value[1] } }));
                    break;
            }
        }, 500);
    };

    useEffect(() => {
        setValue([min, max]);
        return () => {
            clearTimeout(debounceRef.current);
        }
    }, [min, max]);

    const valuetext = (value: number) => {
        switch (type) {
            case "price":
                return `€ ${value.toLocaleString(`${activeLanguage}-${activeLanguage.toUpperCase()}`)}`;
            case "mileage":
                return `${value.toLocaleString(`${activeLanguage}-${activeLanguage.toUpperCase()}`)} km`;
            case "year":
                return `${value}`;
            case "emission":
                return `${value} g/km`;
        }
    }

    const getStepAmount = () : number => {
        switch (type) {
            case "price":
                return 1000;
            case "mileage":
                return 10000;
            case "year":
                return 1;
            case "emission":
                return 1;
        }
    }

    return (
        <Box sx={{
            width: '90%',
            margin: 'auto',
            border: '1px solid transparent',
        }}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <Typography variant='body2'>{valuetext(min)}</Typography>
                <Typography variant='body2'>{valuetext(max)}</Typography>
            </Box>
            <Slider
                name={id}
                id={id}
                min={min}
                max={max}
                getAriaLabel={() => label}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                step={getStepAmount()}
            />
        </Box>
    );
}
import React from "react";
import { ReactNode, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { setVehicleState } from "redux/features/vehicleStateSlice";

type Props = {
  state?: string,
  children: ReactNode;
};

const PageWrapper = (props: Props) => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (props.state) {
  //     dispatch(setVehicleState(props.state));
  //     console.warn(props.state);
  //   }
  // }, [dispatch, props]);

  return (
    <>{props.children}</>
  );
};

export default PageWrapper;
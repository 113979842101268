import React from "react";
import { Container, Grid, Typography, Link } from "@mui/material";
import { MoveFadeInAnimation, BounceAnimation } from "hooks/useAnimations";
import useLanguage from "hooks/useLanguage";

const NotFound = () => {
    const { activeLanguage, t } = useLanguage();

    return (
        <React.Fragment>
            <section className="h-full py-8 flex">
                <Container className="my-auto">
                    <Grid container spacing={3} className="my-20 flex items-center justify-center text-center">
                        <Grid item xs={12} sm={12} md={10}>
                            <MoveFadeInAnimation>
                                <Typography variant="h1" className="font-bold">
                                    {t("Uh Oh!")}
                                </Typography>
                            </MoveFadeInAnimation>
                            <MoveFadeInAnimation delay={100}>
                                <Typography variant="h3">
                                    {t("The page you were looking for doesn't exist.")}
                                </Typography>
                            </MoveFadeInAnimation>
                            <MoveFadeInAnimation delay={500}>
                                <Typography variant="body1" className="">
                                    {t("You might have typed in the wrong address or the page has moved. In the meantime, try again or ")}
                                    <a href="/" className="text-primary-blue font-bold">{t("return to the home page")}</a>
                                    {"."}
                                </Typography>
                            </MoveFadeInAnimation>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <BounceAnimation delay={500}>
                                <img className="mx-auto" src="/images/no-results.png" />
                            </BounceAnimation>
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </React.Fragment>
    )
};

export default NotFound;
import { MouseEvent, useState } from "react";

interface DragInput {
    onDragLeft: (e: MouseEvent) => void;
    onDragRight: (e: MouseEvent) => void;
    onClickFallback?: () => void;
    // onDragStart: () => void;
    // onDragEnd: () => void;
}

interface DragOutput {
    onMouseDown: (e: MouseEvent) => void;
    onMouseMove: (e: MouseEvent) => void;
    onMouseUp: (e: MouseEvent) => void;
}

export default (input: DragInput): DragOutput => {
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [dragEnd, setDragEnd] = useState({ x: 0, y: 0 });

    const minDragDistance = 50;

    const onMouseDown = (e: MouseEvent) => {
        setDragEnd({ x: 0, y: 0 });
        setDragStart({ x: e.clientX, y: e.clientY });
    };

    const onMouseMove = (e: MouseEvent) => {
        setDragEnd({ x: e.clientX, y: e.clientY });
    };

    const onMouseUp = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!dragStart.x || !dragEnd.x) {
            if (input.onClickFallback) {
                input.onClickFallback();
            }
        }
        // if (!dragStart.x || !dragEnd.x) return;

        const distanceX = dragEnd.x - dragStart.x;
        const isLeftDrag = distanceX > minDragDistance;
        const isRightDrag = distanceX < -minDragDistance;

        if(isLeftDrag) {
            input.onDragLeft(e);
        }
        if(isRightDrag) {
            input.onDragRight(e);
        }

        // if (isLeftDrag) {
        //     input.onDragLeft(e);
        // } else if (isRightDrag) {
        //     input.onDragRight(e);
        // } else {            
        //     if (input.onClickFallback) {
        //         input.onClickFallback(e);
        //     }
        // }
    };

    return {
        onMouseDown,
        onMouseMove,
        onMouseUp
    };
};
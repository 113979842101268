import React, { useState, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import VehicleCard from 'components/common/VehicleCard';
import { Grid, IconButton, Box } from '@mui/material';
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import useLanguage from 'hooks/useLanguage';

type Props = {
    id: number;
}

export default function AanbodSlider({ id }: Props) {
    const { t } = useLanguage();
    const Vehicles = useAppSelector((state) => state.vehicleStateSlice.vehicles).slice(0, 8);
    const [perPage, setPerPage] = useState(4);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        const updatePerPage = () => {
            if (window.innerWidth < 600) {
                setPerPage(1);
            } else if (window.innerWidth < 900) {
                setPerPage(2);
            } else if (window.innerWidth < 1200) {
                setPerPage(3);
            } else {
                setPerPage(4);
            }
        };

        window.addEventListener('resize', updatePerPage);
        updatePerPage();

        return () => window.removeEventListener('resize', updatePerPage);
    }, []);

    const handleNextPage = () => {
        setCurrentPage(currentPage => Math.min(currentPage + 1, Math.ceil(Vehicles.length - perPage)));
    };
    
    const handlePrevPage = () => {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 0));
    };
    

    const startIndex = currentPage;
    const endIndex = Math.min(startIndex + perPage, Vehicles.length);
    const displayedVehicles = Vehicles.slice(startIndex, endIndex);

    const absoluteStyles = {
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 999,
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "white",
        position: "absolute",
        borderRadius: "50%",
        cursor: "pointer",
        ":hover": {
            backgroundColor: "rgba(0,0,0,0.5)",
        },
        ":disabled": {
            backgroundColor: "rgba(0,0,0,0.4)",
            color: "rgba(255,255,255,0.5)",
        },
    }

    return (
        <Box className="relative mx-auto w-full" sx={{ flexGrow: 1 }}>
            <IconButton
                sx={{ ...absoluteStyles, left: "-4%" }}
                aria-label={t("Previous")} 
                disabled={currentPage === 0} 
                onClick={handlePrevPage}
            >
                <NavigateBeforeIcon />
            </IconButton>
            
            <Grid container className='mx-auto'>
                {displayedVehicles.map((vehicle, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <VehicleCard hasThumbnailSlider={false} data={vehicle} internalId={vehicle.internal_id} make={vehicle.make} model={vehicle.model} price={vehicle.sell_price} thumbnails={(typeof vehicle.images.image !== undefined) ? vehicle.images.image : []} />
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            </Box>
            <IconButton
                sx={{...absoluteStyles,right: "-4%"}}
                aria-label={t("Next")}
                disabled={currentPage + perPage >= Vehicles.length} 
                onClick={handleNextPage}>
                <NavigateNextIcon />
            </IconButton>
        </Box>
    );
}

const curPath = window.location.pathname;
const activeLanguage = curPath.split('/')[1];

export const capitalize = (str: string): string => {
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const htmlEntitiesDecode = (str: string): string => {
    const result = str
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/<\/p>/g, '</p>')
        .replace(/&amp;/g, '&')
        .replace(/&nbsp;/g, ' ')
        .replace(/&apos;/g, "'")
        .replace(/&quot;/g, '"')         
        .replace(/&euro; /g, '€')
        .replace(/&euro;/g, '€')
        .replace(/&mdash;/g, '—')
        .replace(/&ndash;/g, '-')
        .replace(/&rsquo;/g, "'")
        .replace(/&lsquo;/g, "'")
        .replace(/&rdquo;/g, '"')
        .replace(/&ldquo;/g, '"')
        .replace(/&hellip;/g, '...')
    return result;
};

export const toLocaleDate = (date: string): string => {
    return new Date(date).toLocaleDateString(`${activeLanguage}-${activeLanguage.toUpperCase()}`)
};

export const intToLocaleString = (str: number): string => {
    return str.toLocaleString(`${activeLanguage}-${activeLanguage.toUpperCase()}`);
};

export const slugify = (str:string): string => {
    return String(str)
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
}